import "../scss/main.scss";
import 'slick-carousel';

$(function(){

    var adminBar;
    if( $('#wpadminbar') ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar = 0; }

    $('main').css( 'padding-top', $('#masthead').innerHeight() );

    if( $('#wpadminbar').length != 0){
        $('#breadcrumb').css( 'top', $('#masthead').innerHeight() + adminBar );
    }else{
        $('#breadcrumb').css( 'top', $('#masthead').innerHeight() );
    }

    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    $('#down').on('click', function(){
        $('html, body').animate({scrollTop: $('h1').offset().top - $('#masthead').innerHeight()*2}, "slow");
        return false;
    });

    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation-mobile').toggleClass('open');
    });

    $('.bt-search').on('click', function(){
        $('#search').toggleClass('opensearch');
    });

    // INIT ALL FUNCTIONS
    resize();
    if( $('.backImg').length != 0) {imgBack(); }
    if( $('.bloc--carrousel').length != 0 || $('.bloc--slider').length != 0) { sliders(); }
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();
    
    // ACTIONS WINDOW
    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    $(window).on('resize', function(){
        resize();
    });
});

function resize(){
    $('main').css( 'padding-top', $('#masthead').innerHeight() );

    if($('body.admin-bar').length != 0){
        $('#masthead').css('top', $('#wpadminbar').innerHeight());
        if($(window).width() < 587 && $(window).scrollTop() != 0 ){
            $('body.admin-bar #btNav').css('top', ($('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
        }else{
            $('body.admin-bar #btNav').css('top', ($('#wpadminbar').innerHeight() + $('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
        }
    }

    if( $(window).width() < 456 ){
        $('.bloc--txt-entete').not('.slick-initialized').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            respondTo: 'slider',
            responsive: true,
            arrows: true,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '18px',
            prevArrow: '<span class="slide-prev"><i class="fa-solid fa-chevron-left"></i></span>',
            nextArrow: '<span class="slide-next"><i class="fa-solid fa-chevron-right"></i></span>',
        });
    }else{
        var hauteurEntete = [];
        $('.bloc--txt-entete .bloc--entete').each(function(){
            hauteurEntete.push($(this).innerHeight());
        });
        var hauteursEntete = Math.max(...hauteurEntete);
        $('.bloc--txt-entete .bloc--entete').css('height', hauteursEntete);
    }
}

function actionsScroll(){
    $('.site-search').removeClass('active');

    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling');
        $('body.admin-bar #btNav').css('top', ( $('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) ); 
    } else {
        $('body').removeClass('scrolling');
        $('body.admin-bar #btNav').css('top', ( $('#wpadminbar').innerHeight() + $('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
    }

    if(scrolled > 0 && $(window).width() < 587){
        $('body.admin-bar #btNav').css('top', ( $('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) ); 
    } else {
        $('body.admin-bar #btNav').css('top', ( $('#wpadminbar').innerHeight() + $('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();
        }	
    });
}

function sliders(){
    $('.bloc--carrousel').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-chevron-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-chevron-right"></i></span>',
    });

    $('.bloc--slider').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '18px',
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-left').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-left');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-right').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-right');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}